page.PageClass = class PageClass{



    constructor(data = {}){

        this.resizeId = null;
        this.resizeEvents = [];

        this.shown = false;

    }



    create(){

        return {
            shown: () => this.shown,
            init: this.init.bind(this),
            show: this.show.bind(this),
            hide: this.hide.bind(this),
            destroy: this.destroy.bind(this),
            addResizeEvent: this.addResizeEvent.bind(this)
        };
        
    }



    resizeEventsDo(){
        
        for(let i = 0; i < this.resizeEvents.length; i++){
            this.resizeEvents[i]();
        }

        return true;

    }



    addResizeEvent(func){
        
        this.resizeEvents.push(func);

        return true;

    }



    init(ajaxBool = false){

        this.resizeEvents = [];
        this.resizeId = v.addResize(['w', this.resizeEventsDo.bind(this), 250]);

        return true;
        
    }



    show(ajaxBool = false){

        this.shown = true;

        return true;
        
    }



    hide(ajaxBool = false){

        this.shown = false;

        return true;
        
    }



    destroy(ajaxBool = false){
        
        v.deleteResize(this.resizeId);

        return true;

    }
    


};